<template>
  <div v-if="status">
    <div v-if="show">
      <div v-for="(scrape, key) in status" :key="key" class="message" :class="{'is-warning':key !== 'receipt' && scrape.lastTime && scrape.lastTime < (fullStatus.time - 320000), 'is-danger': key !== 'receipt' && !scrape.lastTime && scrape.startTime < fullStatus.time - 320000, 'is-info': key !== 'receipt' && (!requests || !requests[key])}">
        <div  v-if="key !== 'receipt'" class="message-body">
          {{ scrape.url }}<br>
          Start: {{ $parent.showTime(scrape.startTime) }}<br>
          <span v-if="scrape.lastTime">Last: {{ $parent.showTime(scrape.lastTime) }}</span>
        </div>
      </div>
    </div>
    <a class="button is-small" :class="{'is-warning': warning, 'is-danger': danger, 'is-info': info}" @click="show = !show">{{ statusArray.length }} items</a>
  </div>
</template>

<script>
export default {
  name: 'Statuses',
  props: ['status', 'requests', 'fullStatus'],
  data () {
    return {
      show: false
    };
  },
  computed: {
    statusArray () {
      const statuses = [];
      for (const key in this.status) {
        if (key !== 'receipt') {
          statuses.push({
            key,
            status: this.status[key]
          });
        }
      }
      return statuses;
    },
    worstLevel () {
      return this.statusArray.reduce((acc, scrape) => {
        if (!scrape.status.lastTime && scrape.url !== 'urls' && scrape.status.startTime < this.fullStatus.time - 320000) {
          return 3;
        }
        if (scrape.status.lastTime && scrape.status.lastTime < (this.fullStatus.time - 320000)) {
          return Math.max(2, acc);
        }
        return acc;
      }, 0);
    },
    danger () {
      return this.worstLevel === 3;
    },
    warning () {
      return this.worstLevel === 2;
    },
    info () {
      return this.worstLevel === 1;
    }
  }
};
</script>
