<template>
  <div class="about">
    <h1 class="title">Odds Snapshot</h1>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Sport</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="sport" @change="changeSport">
            <option :value="false">All</option>
            <option v-for="s in sports" :key="s" :value="s">{{ s }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Provider</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="provider" @change="changeSport">
            <option :value="false">All</option>
            <option v-for="c in bookmakers" :key="c" :value="c">{{ c }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Competition</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="competition" @change="changeCompetition">
            <option :value="false">All</option>
            <option v-for="c in competitions" :key="c.id" :value="c.id">{{ c.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Has Market</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="hasMarket" @change="changeMarket">
            <option :value="false">All</option>
            <option v-for="c in oddsMarkets" :key="c" :value="c">{{ c }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Event</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="race" @change="changeRace(race)">
            <option v-for="race in events" :key="JSON.stringify(race)" :value="race">{{ race.name }} {{ eventTime(race.time) }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control" v-if="race">
        <button class="button is-static">Display</button>
      </div>
      <div class="control" v-if="race">
        <div class="select">
          <select v-model="market">
            <option value="all">All Markets</option>
            <option v-for="marketName in raceMarkets" :key="marketName" :value="marketName">{{ marketName }}</option>
          </select>
        </div>
      </div>
      <div class="control" v-if="race">
        <div class="select">
          <select v-model="bookmaker">
            <option value="all">All Providers</option>
            <option v-for="bookmaker in bookmakers" :key="bookmaker" :value="bookmaker">{{ bookmaker }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-primary" @click="refresh">Refresh Current List</button>
      </div>
      <div class="control" v-if="race">
        <button class="button is-warning" @click="race = null">Reset</button>
      </div>
      <div class="control" v-if="selected.length > 1">
        <div class="select">
          <select v-model="first">
            <option v-for="i in selected" :key="'select' + horses[i].find(m => m).selection.id" :value="horses[i].find(m => m).selection.id">{{ horses[i].find(m => m).selection.name }}</option>
          </select>
        </div>
      </div>
      <div class="control" v-if="selected.length > 1">
        <button class="button is-info" @click="combine">Combine Selection Names</button>
      </div>
    </div>
    <div class="message" v-if="selected.length > 1">
      <div class="message-body">
        Combining:
        <p v-for="i in selected" :key="'selecting' + horses[i].find(m => m).selection.id">{{ horses[i].find(m => m).selection.name }}</p>
        As: {{ firstName }}
      </div>
    </div>
    <div class="message" v-if="selectedEvents.length > 1">
      <div class="message-body">
        Combine times on:
        <p v-for="event of selectedEvents" :key="'selectedEvent' + JSON.stringify(event)">{{ event.name }} {{ eventTime(event.time) }}</p>
        <div class="field has-addons">
          <div class="control">
            <button class="button is-static">Correct Time</button>
          </div>
          <div class="control">
            <div class="select">
              <select v-model="primaryEvent">
                <option v-for="event of selectedEvents" :key="'selecting' + JSON.stringify(event)" :value="event">{{ eventTime(event.time) }}</option>
              </select>
            </div>
          </div>
          <div class="control">
            <button class="button is-primary" @click="combineEvent">Combine both events to {{ eventTime(primaryEvent.time) }}</button>
          </div>
        </div>
      </div>
    </div>
    <table class="table" v-if="!race">
      <thead>
        <tr>
          <th>Event</th>
          <th>Time</th>
          <th>Go</th>
          <th>Delete <br><small>ONLY FOR DUPLICATE GAMES WITH INCORRECT NAME TRANSLATIONS</small></th>
          <th>Reverse Name</th>
          <th>Time Merge Info</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="r in events" :key="'list' + JSON.stringify(r)"  :class="{'is-selected': selectedEvents.indexOf(r) > -1, 'has-background-grey':  selectedEvents.indexOf(r) === -1 && selectedEvents.length > 0 && !eventNamesMatch(selectedEvents[0].name, r.name)}" @click="selectEvent(r)">
          <td>{{ r.name }}</td>
          <td>{{ eventTime(r.time) }}</td>
          <td><button class="button is-primary is-small" @click.stop="changeRace(r)">Go</button></td>
          <td><button class="button is-danger is-small" @click.stop="deleteEvent(r)">Delete</button></td>
          <td><button class="button is-warning is-small" @click.stop="reverseEvent(r)">Reverse</button></td>
          <td>{{ eventTimeMerge(r) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="table-container" v-else>
      <div class="message" v-if="event">
        <div class="message-body">
          Loaded {{ event }}
        </div>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th>Scraper</th>
          <template v-for="scraper in scrapers">
            <th :key="scraper.scraper + 'scraper'" :colspan="scraper.columns">{{ scraper.scraper }} {{ scraperTime(scraper) }}</th>
          </template>
        </tr>
        <tr>
          <th>Provider</th>
          <template v-for="(provider, index) in providers">
            <th :key="provider.provider + 'provider' + index" :colspan="provider.columns">{{ provider.provider }}</th>
          </template>
        </tr>
        <tr>
          <th>Market</th>
          <template v-for="(market, index) in markets">
            <th :key="market.market + ' ' + index" :colspan="market.columns">{{ market.market }}</th>
          </template>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(horse, index) in horses" :key="index + 'horse'" :class="{'is-selected': selected.indexOf(index) > -1}" @click="select(index)">
            <td>{{ horse.find(m => m).selection.name }}<span v-if="horse.find(m => m).selection.side">&nbsp;{{ horse.find(m => m).selection.side }} {{ horse.find(m => m).selection.handicap }}</span></td>
            <td v-for="(item,j) in horse" :key="index + ' ' + j + 'horse'">
              <div v-if="item">
                <span v-if="item.odds">{{ item.odds.toFixed(2) }}</span>
                <span v-else-if="item.buy || item.sell">B: {{ item.buy }}|S: {{ item.sell }}</span>
                <span v-else-if="item.back || item.last || item.lay">{{ item.back }}|{{ item.last }}|{{ item.lay }}</span>
                <span v-else>N/A</span>
                <div v-if="item.market.places && item.market.placeDivision" class="is-size-7">{{ item.market.places }} 1/{{ item.market.placeDivision }}</div>
                <div class="is-size-7" v-if="item.changed">{{ changeTime(item.changed) }}</div>
                <div class="is-size-7" v-if="item.updated">{{ changeTime(item.updated) }}</div>
                <div class="is-size-7" v-if="item.selection">{{ item.selection.status }}</div>
                <div class="is-size-7" v-if="item.selection">Market {{ item.market.status }} <span v-if="item.market.inPlay">In Play</span></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Odds',
  components: { },
  data () {
    return {
      race: null,
      selected: [],
      market: 'all',
      bookmaker: 'all',
      first: false,
      sport: false,
      competition: false,
      provider: false,
      hasMarket: false,
      selectedEvents: [],
      primaryEvent: false
    };
  },
  computed: {
    allOdds () {
      return this.$store.state.odds;
    },
    odds () {
      return this.$store.state.odds.filter(item => {
        if (this.market !== 'all' && item.market.name !== this.market) {
          return false;
        }
        if (this.bookmaker !== 'all' && item.provider !== this.bookmaker) {
          return false;
        }
        return true;
      });
    },
    event () {
      if (this.$store.state.odds.length > 0) {
        return this.$store.state.odds[0].event.name;
      }
      return false;
    },
    events () {
      return this.$store.getters.events;
    },
    competitions () {
      return this.$store.state.competitions;
    },
    sports () {
      return this.$store.state.sports;
    },
    columns () {
      const columns = [];
      this.odds.forEach(item => {
        if (!columns.find(c => c.scraper === item.di.s && c.provider === item.provider && c.market === item.market.name)) {
          columns.push({
            scraper: item.di.s,
            provider: item.provider,
            market: item.market.name
          });
        }
      });
      return columns.sort((a, b) => {
        if (a.scraper === b.scraper) {
          if (a.provider === b.provider) {
            return a.market.localeCompare(b.market);
          } else {
            return a.provider.localeCompare(b.provider);
          }
        } else {
          if (!a.scraper) {
            return 1;
          }
          if (!b.scraper) {
            return -1;
          }
          return a.scraper.localeCompare(b.scraper);
        }
      });
    },
    scrapers () {
      const scrapers = this.columns.map(a => a.scraper);
      const distinct = [...new Set(scrapers)];
      return distinct.map(scraper => {
        return {
          columns: scrapers.filter(s => s === scraper).length,
          scraper: scraper
        };
      });
    },
    providers () {
      return this.scrapers.flatMap(scraper => this.scraperProviders(scraper));
    },
    bookmakers () {
      return this.$store.state.providers;
    },
    oddsMarkets () {
      return this.$store.state.markets;
    },
    markets () {
      return this.scrapers.flatMap(scraper => this.scraperProviders(scraper).flatMap(provider => this.scraperProviderMarkets(scraper, provider)));
    },
    raceMarkets () {
      const markets = this.allOdds.map(a => a.market.name);
      return [...new Set(markets)];
    },
    horses () {
      const horses = {};
      this.odds.forEach(item => {
        const handicap = (item.selection.handicap || item.selection.handicap === 0) ? item.selection.handicap : '';
        const side = item.selection.side || '';
        if (horses[item.selection.name + '_' + handicap + '_' + side] === undefined) {
          horses[item.selection.name + '_' + handicap + '_' + side] = [];
        }
        horses[item.selection.name + '_' + handicap + '_' + side].push(item);
      });
      console.log(horses);
      return Object.values(horses).sort((a, b) => {
        if (!a[0].selection.name && !b[0].selection.name) {
          return 0;
        } else if (!a[0].selection.name) {
          return -1;
        } else if (!b[0].selection.name) {
          return 1;
        }
        return a[0].selection.name.localeCompare(b[0].selection.name);
      }).map(horse => {
        const integerIndexed = {};
        horse.forEach(a => {
          const aIndex = this.columns.findIndex(c => c.scraper === a.di.s && c.provider === a.provider && c.market === a.market.name);
          integerIndexed[aIndex] = a;
        });
        const returnArray = [];
        for (let i = 0; i < this.columns.length; i++) {
          if (integerIndexed[i] === undefined) {
            returnArray.push(null);
          } else {
            returnArray.push(integerIndexed[i]);
          }
        }
        return returnArray;
      // }).sort((a, b) => {
        // const aMax = Math.max(...a.filter(item => item && item.market.name === 'Winner' && (item.back || item.odds)).map(item => {
        //   if (item.odds) {
        //     return item.odds;
        //   } else {
        //     return item.back;
        //   }
        // }));
        // const bMax = Math.max(...b.filter(item => item && item.market.name === 'Winner' && (item.back || item.odds)).map(item => {
        //   if (item.odds) {
        //     return item.odds;
        //   } else {
        //     return item.back;
        //   }
        // }));
        // return aMax - bMax;
      });
    },
    firstName () {
      let f;
      if (this.first) {
        const firstIndex = this.selected.findIndex(i => this.horses[i].find(m => m).selection.id === this.first);
        f = this.horses[this.selected[firstIndex]].find(m => m);
      } else {
        f = this.horses[this.selected[0]].find(m => m);
      }
      return f.selection.name + (f.selection.side ? ' ' + f.selection.side + ' ' + f.selection.handicap : '');
    }
  },
  methods: {
    combine () {
      const selected = [];
      if (this.first) {
        const firstIndex = this.selected.findIndex(i => this.horses[i].find(m => m).selection.id === this.first);
        if (firstIndex > -1) {
          selected.push(this.horses[this.selected[firstIndex]].find(m => m).selection.id);
        }
      }
      this.selected.forEach(i => {
        if (selected.indexOf(this.horses[i].find(m => m).selection.id) === -1) {
          selected.push(this.horses[i].find(m => m).selection.id);
        }
      });
      this.selected = [];
      this.first = false;
      this.$store.dispatch('combineCanonicals', selected).then(() => {
        if (this.race) {
          this.$store.dispatch('getRaceOdds', this.race);
        } else {
          this.$store.dispatch('getOdds');
        }
      });
    },
    combineEvent () {
      if (!this.primaryEvent || this.selectedEvents.length < 2) {
        return;
      }
      this.$store.dispatch('combineEvents', {
        events: this.selectedEvents.filter(a => a.time !== this.primaryEvent.time),
        time: this.primaryEvent.time,
        sport: this.sport
      }).then(() => {
        this.primaryEvent = false;
        this.selectedEvents = [];
        this.changeSport();
        this.changeMarket();
        this.$store.dispatch('getTimes');
      });
    },
    select (index) {
      if (this.selected.indexOf(index) > -1) {
        this.selected.splice(this.selected.indexOf(index), 1);
      } else {
        this.selected.push(index);
      }
    },
    selectEvent (event) {
      if (this.selectedEvents.indexOf(event) > -1) {
        this.selectedEvents.splice(this.selectedEvents.indexOf(event), 1);
      } else {
        if (this.selectedEvents.length > 0 && !this.eventNamesMatch(this.selectedEvents[0].name, event.name)) {
          this.selectedEvents = [];
        }
        this.selectedEvents.push(event);
      }
    },
    eventNamesMatch (name1, name2) {
      return name1 === name2 || (name1.replace(/\d{2}:\d{2}/, '') === name2.replace(/\d{2}:\d{2}/, ''));
    },
    eventTimeMerge (event) {
      const from = this.$store.state.times.filter(time => this.eventNamesMatch(time.name, event.name) && time.time === event.time);
      const to = this.$store.state.times.filter(time => this.eventNamesMatch(time.name, event.name) && time.newTime === event.time);
      let string = '';
      if (from.length > 0) {
        for (const f of from) {
          string += 'This event time is being merged into ' + this.eventTime(f.time) + '. ';
        }
      }
      if (to.length > 0) {
        string += 'Other start times merged: ';
        for (const t of to) {
          string += this.eventTime(t.time) + ' ';
        }
      }
      return string;
    },
    eventTime (time) {
      return moment.unix(time).format('D/MMM/YY HH:mm');
    },
    changeTime (time) {
      return moment(time).format('HH:mm:ss');
    },
    changeRace (race) {
      this.race = race;
      this.market = 'all';
      this.$store.dispatch('getRaceOdds', race);
    },
    deleteEvent (event) {
      this.$store.dispatch('deleteEvent', event).then(() => {
        this.changeMarket();
      });
    },
    reverseEvent (event) {
      this.$store.dispatch('reverseEvent', event).then(() => {
        this.changeMarket();
      });
    },
    changeSport () {
      let filter = '';
      if (this.sport) {
        filter += 'sport=' + this.sport + '&';
      }
      this.$store.dispatch('getCompetitions', filter);
      if (this.competition) {
        filter += 'competition=' + this.competition + '&';
      }
      this.$store.dispatch('getMarkets', filter);
      if (this.hasMarket) {
        filter += 'market=' + this.hasMarket + '&';
      }
      this.$store.dispatch('getEvents', filter);
    },
    changeCompetition () {
      let filter = '';
      if (this.sport) {
        filter += 'sport=' + this.sport + '&';
      }
      if (this.competition) {
        filter += 'competition=' + this.competition + '&';
      }
      if (this.provider) {
        filter += 'provider=' + this.provider + '&';
      }
      this.$store.dispatch('getMarkets', filter);
      if (this.hasMarket) {
        filter += 'market=' + this.hasMarket + '&';
      }
      this.$store.dispatch('getEvents', filter);
    },
    changeMarket () {
      let filter = '';
      if (this.sport) {
        filter += 'sport=' + this.sport + '&';
      }
      if (this.competition) {
        filter += 'competition=' + this.competition + '&';
      }
      if (this.provider) {
        filter += 'provider=' + this.provider + '&';
      }
      if (this.hasMarket) {
        filter += 'market=' + this.hasMarket + '&';
      }
      this.$store.dispatch('getEvents', filter);
    },
    refresh () {
      if (this.race) {
        this.$store.dispatch('getRaceOdds', this.race);
      } else {
        this.$store.dispatch('getOdds');
      }
    },
    scraperProviders (scraper) {
      const providers = this.columns.filter(c => c.scraper === scraper.scraper).map(a => a.provider);
      const distinct = [...new Set(providers)];
      return distinct.map(provider => {
        return {
          columns: providers.filter(s => s === provider).length,
          provider: provider
        };
      });
    },
    scraperProviderMarkets (scraper, provider) {
      const markets = this.columns.filter(c => c.scraper === scraper.scraper && c.provider === provider.provider).map(a => a.market);
      const distinct = [...new Set(markets)];
      return distinct.map(market => {
        return {
          columns: markets.filter(s => s === market).length,
          market: market
        };
      });
    },
    scraperTime (scraper) {
      let lastTime = 0;
      this.horses.forEach(horse => {
        horse.forEach(market => {
          if (market && market.di.s === scraper.scraper && (market.changed > lastTime || market.updated > lastTime)) {
            lastTime = market.changed ?? market.updated;
          }
        });
      });
      return this.changeTime(lastTime);
    }
  },
  mounted () {
    this.$store.dispatch('getSports');
    this.$store.dispatch('getCompetitions');
    this.$store.dispatch('getMarkets');
    this.$store.dispatch('getProviders');
    this.$store.dispatch('getOdds');
    this.$store.dispatch('getEvents');
    this.$store.dispatch('getTimes');
  }
};
</script>
