<template>
  <div class="about">
    <h1 class="title">System Status <button class="is-small button is-danger" @click="rebootAll">Reboot System</button></h1>
    <div class="tabs">
      <ul>
        <li :class="{'is-active': isActive === 'scraper'}"><a @click="isActive = 'scraper'">Scrapers</a></li>
        <li :class="{'is-active': isActive === 'provider'}"><a @click="isActive = 'provider'">Providers</a></li>
        <li :class="{'is-active': isActive === 'odds'}"><a @click="isActive = 'odds'">Odds</a></li>
        <li :class="{'is-active': isActive === 'db'}"><a @click="isActive = 'db'">Database</a></li>
      </ul>
    </div>
    <div v-if="isActive === 'scraper'">
      <h3 class="subtitle">Last Status Time: {{ time }}  <button class="is-small button is-primary" @click="getStatus">Refresh</button></h3>
      <div class="message" :class="{'is-danger':queue > 0}"><div class="message-body">Queue Length: {{ queue }}
        <p v-if="queue > 0"><router-link to="/urls" class="button is-small is-danger">Check outstanding urls to identify issues.</router-link></p>
      </div></div>
      <div class="field has-addons">
        <div class="control">
          <button class="button is-static">Description</button>
        </div>
        <div class="control">
          <div class="select">
            <select v-model="statusDescription">
              <option value="">All</option>
              <option v-for="description in descriptions" :key="description">{{ description }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead>
          <tr>
            <th>Hostname/IP <span @click="sort('hostname', -1)">&#9660;</span> <span @click="sort('hostname', 1)">&#9650;</span></th>
            <th>Description <span @click="sort('description', -1)">&#9660;</span> <span @click="sort('description', 1)">&#9650;</span></th>
            <th>Status Time <span @click="sort('statusTime', -1)">&#9660;</span> <span @click="sort('statusTime', 1)">&#9650;</span></th>
            <th>Request Time <span @click="sort('request', -1)">&#9660;</span> <span @click="sort('request', 1)">&#9650;</span></th>
            <th>Receipt Time <span @click="sort('receipt', -1)">&#9660;</span> <span @click="sort('receipt', 1)">&#9650;</span></th>
            <th>Capacity <span @click="sort('capacity', -1)">&#9660;</span> <span @click="sort('capacity', 1)">&#9650;</span></th>
            <th>Queue</th>
            <th>Currently Scraping <span @click="sort('status', -1)">&#9660;</span> <span @click="sort('status', 1)">&#9650;</span></th>
            <th>Requests</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(conn, index) in statuses" :key="index">
            <td>
              {{ conn.host }}
              <div class="is-size-7 has-text-grey-light" v-for="ip in conn.ips" :key="ip + '_' + index">{{ ip }}</div>
            </td>
            <td>{{ conn.description }}<br>
              <span v-if="conn.version" class="is-size-7 has-text-grey-light">{{ conn.version }}</span></td>
            <td :class="{'has-background-danger': conn.status && conn.status.receipt < status.time - 320000}"><span v-if="conn.status && conn.status.receipt">{{ showTime(conn.status.receipt) }}</span></td>
            <td><span v-if="conn.lastRequest">{{ showTime(conn.lastRequest) }}</span></td>
            <td><span v-if="conn.lastReceipt">{{ showTime(conn.lastReceipt) }}</span></td>
            <td>{{ conn.capacity }}</td>
            <td>
              <div v-if="conn.scrapeAbilities">
                <div v-for="ability in conn.scrapeAbilities" :key="index + ability" class="button is-small" :class="{'is-danger':queueLength(ability) > 0}">
                  {{ ability }}: {{ queueLength(ability) }}
                </div>
              </div>
            </td>
            <td>
              <statuses :status="conn.status" :requests="conn.requests" :fullStatus="status"></statuses>
            </td>
            <td>
              <requests :status="conn.status" :requests="conn.requests" :fullStatus="status"></requests>
            </td>
            <td><button class="button is-small is-danger" @click="reboot(conn.index)">Reboot</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="isActive === 'provider'">
      <div class="field has-addons">
        <div class="control">
          <button class="button is-static">Bookmaker</button>
        </div>
        <div class="control">
          <div class="select">
            <select v-model="selectedProvider">
              <option value="">All</option>
              <option v-for="bookmaker in providers" :key="bookmaker">{{ bookmaker }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead>
          <tr>
            <th>Sport</th>
            <th>Bookmaker</th>
            <th>Scrape Provider</th>
            <th>Switch</th>
          </tr>
          </thead>
          <tbody v-for="(data, sport) in statusProviders" :key="'provider' + sport">
          <scrape-provider-line v-for="(config, provider) in data" :config="config" :sport="sport" :provider="provider" :key="'provider' + sport + provider"></scrape-provider-line>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="isActive === 'odds'">
      <h3 class="subtitle">Last Status Time: {{ latest }} <button class="is-small button is-primary" @click="refreshOddsStatus">Refresh</button></h3>
      <div class="table-container">
        <table class="table is-fullwidth is-hoverable">
          <thead>
          <tr>
            <th>Sport</th>
            <th>Provider</th>
            <th>Delayed</th>
            <th>Last Odds Update</th>
            <th>Last Unmatched Update</th>
          </tr>
          </thead>
          <tbody v-for="sportProvider in oddsStatus" :key="sportProvider.provider + sportProvider.sport">
            <tr @click="openProvider(sportProvider.provider + sportProvider.sport)"  :class="{'has-background-warning': (sportProvider.odds && sportProvider.odds.updated < Date.now() - 800000) || delayedOdds(sportProvider).length >= 100}">
              <td>{{sportProvider.sport}}</td>
              <td>{{sportProvider.provider}}</td>
              <td>
                  <span v-if="(sportProvider.odds && sportProvider.odds.updated < Date.now() - 800000) || delayedOdds(sportProvider).length > 0">{{ delayedOdds(sportProvider).length }}<span v-if="delayedOdds(sportProvider).length === 200">+</span></span>
                  <span v-else>None</span></td>
              <td><span v-if="sportProvider.odds">{{ showTime(sportProvider.odds.updated) }}</span></td>
              <td><span v-if="draftStatus.find(a => a.sport === sportProvider.sport && a.provider === sportProvider.provider) && draftStatus.find(a => a.sport === sportProvider.sport && a.provider === sportProvider.provider).odds">{{ showTime(draftStatus.find(a => a.sport === sportProvider.sport && a.provider === sportProvider.provider).odds.updated) }}</span><span v-else>None</span></td>
            </tr>
            <tr v-if="isOpened(sportProvider.provider + sportProvider.sport)">
              <td colspan="5">
                <table class="table is-fullwidth">
                  <thead>
                    <th>Event</th>
                    <th>Market</th>
                    <th>Selection</th>
                    <th>Last Updated</th>
                  </thead>
                  <tbody>
                    <tr v-for="delayed in delayedOdds(sportProvider)" :key="delayed._id">
                      <td>{{ delayed.event.name }}</td>
                      <td>{{ delayed.market.name }}</td>
                      <td>{{ delayed.selection.name }}</td>
                      <td>{{ showTime(delayed.updated) }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="isActive === 'db'">
      <h3 class="subtitle"><button class="is-small button is-primary" @click="getDbStatus">Refresh</button></h3>
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead>
          <tr>
            <th>Name</th>
            <th>Health</th>
            <th>State</th>
            <th>Uptime</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="db in dbStatus" :key="db.name">
            <td>{{ db.name }}</td>
            <td>{{ db.health }}</td>
            <td>{{ db.state }}</td>
            <td>{{ db.uptime }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Statuses from '@/components/Statuses';
import Requests from '@/components/Requests';
import ScrapeProviderLine from '@/components/ScrapeProviderLine';

export default {
  name: 'Status',
  components: { ScrapeProviderLine, Requests, Statuses },
  data () {
    return {
      isActive: 'scraper',
      statusDescription: '',
      selectedProvider: '',
      bookmakers: [
        'Skybet',
        'Bet365',
        'Paddy Power',
        'William Hill',
        '888Sport',
        'Betfair.com Sportsbook',
        'Betfred',
        'Redzonesports',
        'Ladbrokes',
        'Spreadex',
        'Novibet',
        'Gentingbet',
        'Sportnation',
        'Boyle Sports',
        'BetWay',
        'BetVictor',
        '10Bet',
        'VBet',
        'Unibet',
        'Tote',
        'SBK',
        'Mansion Bet',
        'Sporting Index',
        'Coral',
        'Betfair Exchange',
        'PokerStars Sports'
      ],
      sports: [
        'Horse Racing',
        'Football'
      ],
      sorting: false,
      grouped: false,
      opened: []
    };
  },
  computed: {
    urls () {
      return this.$store.state.urls;
    },
    status () {
      return this.$store.state.socket.status;
    },
    descriptions () {
      return [...new Set(this.status.statuses.map(a => a.description))];
    },
    statusProviders () {
      const returnProviders = {};
      for (const sport in this.status.config.providers) {
        returnProviders[sport] = {};
        for (const provider in this.status.config.providers[sport]) {
          if (this.selectedProvider !== '' && this.selectedProvider !== provider) {
            continue;
          }
          returnProviders[sport][provider] = this.status.config.providers[sport][provider];
        }
      }
      return returnProviders;
    },
    providers () {
      const providers = [];
      for (const sport in this.status.config.providers) {
        for (const provider in this.status.config.providers[sport]) {
          if (providers.indexOf(provider) === -1) {
            providers.push(provider);
          }
        }
      }
      return providers;
    },
    statuses () {
      const statuses = this.status.statuses.filter(a => {
        return this.statusDescription === '' || this.statusDescription === a.description;
      });
      if (this.sorting) {
        switch (this.sorting.field) {
          case 'hostname':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => a.host.localeCompare(b.host));
            } else {
              statuses.sort((a, b) => b.host.localeCompare(a.host));
            }
            break;
          case 'capacity':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => parseInt(a.capacity) - parseInt(b.capacity));
            } else {
              statuses.sort((a, b) => parseInt(b.capacity) - parseInt(a.capacity));
            }
            break;
          case 'description':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => a.description.localeCompare(b.description));
            } else {
              statuses.sort((a, b) => b.description.localeCompare(a.description));
            }
            break;
          case 'statusTime':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => (a.status ? a.status.receipt : 0) - (b.status ? b.status.receipt : 0));
            } else {
              statuses.sort((a, b) => (b.status ? b.status.receipt : 0) - (a.status ? a.status.receipt : 0));
            }
            break;
          case 'receipt':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => (a.lastReceipt || 0) - (b.lastReceipt || 0));
            } else {
              statuses.sort((a, b) => (b.lastReceipt || 0) - (a.lastReceipt || 0));
            }
            break;
          case 'request':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => (a.lastRequest || 0) - (b.lastRequest || 0));
            } else {
              statuses.sort((a, b) => (b.lastRequest || 0) - (a.lastRequest || 0));
            }
            break;
          case 'status':
            if (this.sorting.direction > 0) {
              statuses.sort((a, b) => (a.status ? Object.keys(a.status).length : 0) - (b.status ? Object.keys(b.status).length : 0));
            } else {
              statuses.sort((a, b) => (b.status ? Object.keys(b.status).length : 0) - (a.status ? Object.keys(a.status).length : 0));
            }
            break;
        }
      }
      if (this.grouped) {
        const statii = [];
        statuses.forEach(status => {
          let index = -1;
          switch (this.grouped) {
            case 'hostname':
              index = statii.find(a => a.host === status.host) + 1;
              while (statii[index] && statii[index].host === status.host) {
                index++;
              }
              break;
            case 'description':
              index = statii.find(a => a.description === status.description) + 1;
              while (statii[index] && statii[index].description === status.description) {
                index++;
              }
              break;
          }
          if (index > 0) {
            statii.splice(index, 0, status);
          } else {
            statii.push(status);
          }
        });
        return statii;
      } else {
        return statuses;
      }
    },
    time () {
      return this.showTime(this.status.time);
    },
    oddsStatus () {
      return this.$store.state.statusOdds;
    },
    oddsStatusDelayed () {
      return this.$store.state.statusOddsDelayed;
    },
    draftStatus () {
      return this.$store.state.statusDraft;
    },
    dbStatus () {
      return this.$store.state.dbStatus;
    },
    latest () {
      let latest = 0;
      this.oddsStatus.forEach(a => {
        if (a.odds && a.odds.updated > latest) {
          latest = a.odds.updated;
        }
      });
      this.draftStatus.forEach(a => {
        if (a.odds && a.odds.updated > latest) {
          latest = a.odds.updated;
        }
      });
      return this.showTime(latest);
    },
    queue () {
      return this.urls.filter(url => {
        return url.next < this.status.time - 60000 && !url.processing;
      }).length;
    }
  },
  methods: {
    group (grouping) {
      this.grouped = grouping;
    },
    openProvider (string) {
      if (this.opened.indexOf(string) > -1) {
        this.opened.splice(this.opened.indexOf(string), 1);
      } else {
        this.opened.push(string);
      }
    },
    isOpened (string) {
      return this.opened.indexOf(string) > -1;
    },
    delayedOdds (payload) {
      const delayed = this.oddsStatusDelayed.find(a => a.sport === payload.sport && a.provider === payload.provider);
      if (delayed) {
        return delayed.odds;
      } else {
        return [];
      }
    },
    sort (field, direction) {
      this.sorting = {
        field,
        direction
      };
    },
    showTime (time) {
      return moment(time).format('HH:mm:ss DD MMM YYYY');
    },
    async refreshOddsStatus () {
      this.sports.forEach(sport => {
        this.bookmakers.forEach(provider => {
          this.$store.dispatch('getMostRecentUpdate', { sport, provider });
          if (provider !== 'Betfair Exchange') {
            this.$store.dispatch('getMostRecentDraft', { sport, provider });
            this.$store.dispatch('getOutOfDateUpdates', { sport, provider });
          }
        });
      });
    },
    getStatus () {
      this.$store.dispatch('getUrls');
      this.$store.dispatch('getStatus');
    },
    getDbStatus () {
      this.$store.dispatch('getDbStatus');
    },
    queueLength (ability) {
      return this.urls.filter(url => {
        return url.ability === ability && url.next < this.status.time - 60000 && !url.processing;
      }).length;
    },
    reboot (index) {
      this.$store.dispatch('rebootIndex', index);
    },
    rebootAll () {
      this.$store.dispatch('reboot');
    }
  },
  mounted () {
    this.$store.dispatch('getUrls');
    this.getStatus();
    this.refreshOddsStatus();
    this.getDbStatus();
  }
};
</script>
