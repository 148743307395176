<template>
  <div class="about">
    <h1 class="title">Canonical Items</h1>
    <div class="message">
      <div class="message-body">
        To combine rows, select both rows (the row you want to keep first) and then select combine.
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Sport</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="filter.sport" @change="doFilter">
            <option :value="undefined">All</option>
            <option>Horse Racing</option>
            <option>Football</option>
            <option>American Football</option>
            <option>Rugby Union</option>
            <option>Rugby League</option>
            <option>Darts</option>
            <option>Snooker</option>
            <option>Basketball</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Type</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="filter.type" @change="doFilter">
            <option>competition</option>
            <option>event</option>
            <option>selection</option>
            <option>team</option>
          </select>
        </div>
      </div>
      <div class="control">
        <input class="input" placeholder="Search" v-model="filter.search">
      </div>
      <div class="control" v-if="filter.search.length > 0 && filter.search.length < 3">
        <button class="button is-primary" @click="doFilter">Search</button>
      </div>
    </div>
    <nav class="pagination" role="navigation" aria-label="pagination">
      <a class="pagination-previous" :class="{'is-disabled': filter.page === 0}" :disabled="filter.page === 0" @click="filter.page = Math.max(0, filter.page - 1)">Previous page</a>
      <a class="pagination-next" @click="filter.page = filter.page + 1">Next page</a>
      <ul class="pagination-list">
        <li v-for="i in (filter.page + 2)" :key="'filter.page' + i">
          <a class="pagination-link" :class="{'is-current': i - 1 === filter.page}" @click="filter.page = i - 1">{{ i }}</a>
        </li>
      </ul>
    </nav>
    <table class="table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Name</th>
          <th>Sport</th>
          <th>Timestamp</th>
          <th>Matches</th>
          <th>Select
            <button @click="combine" class="button is-primary">Combine</button>
            <div v-if="selected.length > 0" class="message is-primary">
              <div class="message-body">
                <p v-for="item in selected" :key="item">{{ ($store.state.dictionary.find(a => a._id === item) || { string: item.split('_').pop()}).string }}</p>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <dictionary-line v-for="dictionary in dictionaries" :key="dictionary._id" :dictionary="dictionary"></dictionary-line>
      </tbody>
    </table>
  </div>
</template>

<script>
import DictionaryLine from '@/components/DictionaryLine';
export default {
  name: 'Dictionary',
  components: { DictionaryLine },
  data () {
    return {
      selected: [],
      filter: {
        type: 'competition',
        search: '',
        sport: undefined,
        page: 0
      }
    };
  },
  computed: {
    dictionaries () {
      return this.$store.state.dictionary;
    }
  },
  methods: {
    combine () {
      this.$store.dispatch('combineCanonicals', this.selected).then(() => {
        this.$store.dispatch('getDictionary', this.filter);
      });
      this.selected = [];
    },
    doFilter () {
      this.$store.dispatch('getDictionary', this.filter);
    }
  },
  watch: {
    'filter.search' () {
      if (this.filter.search.length < 1 || this.filter.search.length > 2) {
        this.$store.dispatch('getDictionary', this.filter);
      }
    },
    'filter.page' () {
      this.$store.dispatch('getDictionary', this.filter);
    }
  },
  mounted () {
    console.log(this.filter);
    this.$store.dispatch('getDictionary', this.filter);
  }
};
</script>
