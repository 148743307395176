<template>
  <tr>
    <td>{{ sport }}</td>
    <td>{{ provider }}</td>
    <td>{{ current.scrapeProvider }}</td>
    <td>
      <div class="field has-addons">
        <div class="control">
          <div class="select">
            <select v-model="switchProvider">
              <option v-for="data in config" :value="data.scrapeProvider" :key="data.scrapeProvider">{{ data.scrapeProvider }}</option>
            </select>
          </div>
        </div>
        <div class="control">
          <button class="button is-primary" @click="change()">Change</button>
        </div>
        <div class="control">
          <button class="button is-warning" @click="hold(false)" v-if="current.lock">Unlock</button>
          <button class="button is-info" @click="hold(true)" v-else>Lock</button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ScrapeProviderLine',
  props: ['sport', 'provider', 'config'],
  data () {
    return {
      switchProvider: false
    };
  },
  computed: {
    current () {
      return this.config.find(a => a.current) || this.config[0];
    }
  },
  methods: {
    change () {
      if (this.current.provider !== this.switchProvider) {
        this.$store.dispatch('changeProvider', {
          sport: this.sport,
          provider: this.provider,
          switch: this.switchProvider,
          from: this.current.scrapeProvider
        });
      }
    },
    hold () {
      this.$store.dispatch('holdProvider', {
        sport: this.sport,
        provider: this.provider,
        hold: !this.current.lock
      });
    }
  }
};
</script>
