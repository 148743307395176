<template>
  <div class="about">
    <h1 class="title">Scraper Urls</h1>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Scraper</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="filter.ability">
            <option value="">All</option>
            <option v-for="ability in abilities" :key="ability">{{ ability }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Sport</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="filter.sport">
            <option value="">All</option>
            <option v-for="sport in sports" :key="sport">{{ sport }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-primary" @click="refresh">Refresh</button>
      </div>
    </div>
    <div class="table-container">
      <table class="table">
        <thead>
        <tr>
          <th>Scraper</th>
          <th>Sport</th>
          <th>Event Time</th>
          <th>Url</th>
          <th>Last Scraper</th>
          <th>Currently Scraping?</th>
          <th>Scrape Due?</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><input type="text" v-model="ability" class="input"></td>
          <td><input type="text" v-model="sport" class="input"></td>
          <td><datetime v-model="time" type="datetime"></datetime></td>
          <td><input type="text" v-model="url" class="input"></td>
          <td></td>
          <td></td>
          <td>
            <button class="button is-primary" @click="add(url)">Add</button>
          </td>
        </tr>
        <tr v-for="url in filteredUrls" :key="url._id" :class="{ 'has-background-warning': url.next - Date.now() < -60000 && !url.processing }">
          <td>{{ url.ability }}</td>
          <td>{{ url.sport }}</td>
          <td>{{ tillTime(url.time) }}</td>
          <td style="overflow-x: scroll; max-width: 80%;">{{ url.url }}</td>
          <td><span v-if="url.host">{{ url.host }}</span></td>
          <td>{{ url.processing }}</td>
          <td>{{ tillDue(url.next) }}</td>
          <th>
            <div class="field has-addons">
              <div class="control">
                <button class="button is-primary" @click="run(url)">Run Now</button>
              </div>
              <div class="control">
                <button class="button is-danger" @click="remove(url)">Delete</button>
              </div>
            </div>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import moment from 'moment';

export default {
  name: 'Drafts',
  components: {
    datetime: Datetime
  },
  data () {
    var date = new Date();
    return {
      ability: '',
      sport: '',
      time: date.toISOString(),
      url: '',
      filter: {
        sport: false,
        ability: false
      }
    };
  },
  computed: {
    urls () {
      return this.$store.state.urls;
    },
    sports () {
      return [...new Set(this.urls.map(a => a.sport))];
    },
    abilities () {
      return [...new Set(this.urls.map(a => a.ability))];
    },
    filteredUrls () {
      const filtered = this.urls.filter(url => {
        if (this.filter.sport && url.sport !== this.filter.sport) {
          return false;
        }
        if (this.filter.ability && url.ability !== this.filter.ability) {
          return false;
        }
        return true;
      });
      return filtered.sort((a, b) => a.next - b.next);
    }
  },
  methods: {
    tillDue (time) {
      if (time < (Date.now() - 60000)) {
        return 'Overdue';
      } else if (time < Date.now()) {
        return 'Due';
      } else {
        return Math.floor((time - Date.now()) / 1000) + 's';
      }
    },
    tillTime (time) {
      if (!time) {
        return '';
      }
      const delta = time - Date.now();
      if (delta > 86400000) {
        return Math.floor(delta / 86400000) + ' days';
      } else if (delta > 7200000) {
        return Math.floor(delta / 3600000) + ' hours';
      } else {
        return Math.floor(delta / 60000) + ' minutes';
      }
    },
    run (url) {
      this.$store.dispatch('runUrl', url);
    },
    remove (url) {
      this.$store.dispatch('removeUrl', url);
    },
    add () {
      this.$store.dispatch('addUrl', {
        ability: this.ability,
        sport: this.sport,
        time: moment(this.time).valueOf(),
        url: this.url
      }).then(() => {
        this.ability = '';
        this.sport = '';
        this.url = '';
      });
    },
    refresh () {
      this.$store.dispatch('getUrls');
    }
  },
  mounted () {
    this.$store.dispatch('getUrls');
  }
};
</script>
