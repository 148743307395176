<template>
  <div class="about">
    <h1 class="title">Draft Snapshot</h1>
    <div class="message">
      <div class="message-body">
        Currently shows the next 200 drafts (odds items that haven't been matched with betfair data). There is 1 entry per provider, scraper, event, market and selection combination.
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Scraper</th>
          <th>Provider</th>
          <th>Event</th>
          <th>Time</th>
          <th>Market</th>
          <th>Selection</th>
          <th>Odds</th>
          <th>Updated Time</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <odds-line v-for="bet in odds" :key="bet._id" :odds="bet"></odds-line>
      </tbody>
    </table>
  </div>
</template>

<script>
import OddsLine from '@/components/OddsLine';
export default {
  name: 'Drafts',
  components: { OddsLine },
  computed: {
    odds () {
      return this.$store.state.drafts;
    }
  },
  mounted () {
    this.$store.dispatch('getDrafts');
  }
};
</script>
