<template>
  <div class="about">
    <h1 class="title">Odds Socket</h1>
    <section v-if="ws === null" class="hero is-info">
      Please refresh to connect to websocket
    </section>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Sport</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="sport" @change="changeSport">
            <option :value="false">All</option>
            <option v-for="s in sports" :key="s" :value="s">{{ s }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Provider</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="provider" @change="changeSport">
            <option :value="false">All</option>
            <option v-for="c in bookmakers" :key="c" :value="c">{{ c }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-static">Competition</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="competition" @change="changeCompetition">
            <option :value="false">All</option>
            <option v-for="c in competitions" :key="c.id" :value="c.id">{{ c.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Has Market</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="hasMarket" @change="changeMarket">
            <option :value="false">All</option>
            <option v-for="c in oddsMarkets" :key="c" :value="c">{{ c }}</option>
          </select>
        </div>
      </div>
      <div class="control" v-if="sport">
        <button class="button is-static">Event</button>
      </div>
      <div class="control" v-if="sport">
        <div class="select">
          <select v-model="race" @change="changeRace">
            <option v-for="race in events" :key="JSON.stringify(race)" :value="race">{{ race.name }} {{ eventTime(race.time) }}</option>
          </select>
        </div>
      </div>
      <div class="control" v-if="race">
        <button class="button is-primary" @click="subscribe">Subscribe</button>
      </div>
      <div class="control" v-if="odds.length > 0">
        <button class="button is-primary" @click="compare">Compare REST API</button>
      </div>
    </div>
    <div class="table-container">
      <table class="table" v-if="odds.length > 0">
        <thead>
        <tr>
          <th>Scraper</th>
          <template v-for="scraper in scrapers">
            <th :key="scraper.scraper + 'scraper'" :colspan="scraper.columns">{{ scraper.scraper }}</th>
          </template>
        </tr>
        <tr>
          <th>Provider</th>
          <template v-for="(provider, index) in providers">
            <th :key="provider.provider + 'provider' + index" :colspan="provider.columns">{{ provider.provider }}</th>
          </template>
        </tr>
        <tr>
          <th>Market</th>
          <template v-for="(market, index) in markets">
            <th :key="market.market + ' ' + index" :colspan="market.columns">{{ market.market }}</th>
          </template>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(horse, index) in horses" :key="index + 'horse'">
          <td>{{ horse.find(m => m).selection.name }}</td>
          <td v-for="(item,j) in horse" :key="index + ' ' + j + 'horse'">
            <div v-if="item" :class="{'has-background-warning': !item.initial && item.receiptTime - item.updated > 2000, 'has-background-danger': !item.initial && (item.receiptTime - item.updated > 10000 || (showCompare && restItem(item.id) && restItem(item.id).updated > item.updated)) }">
              <span v-if="item.odds">{{ item.odds.toFixed(2) }}</span>
              <span v-else-if="item.buy || item.sell">B: {{ item.buy }}|S: {{ item.sell }}</span>
              <span v-else-if="item.back || item.last || item.lay">{{ item.back }}|{{ item.last }}|{{ item.lay }}</span>
              <span v-else>N/A</span>
              <div v-if="item.market.places && item.market.placeDivision" class="is-size-7">{{ item.market.places }} 1/{{ item.market.placeDivision }}</div>
              <div class="is-size-7" v-if="item.changed">Change {{ changeTime(item.changed) }}</div>
              <div class="is-size-7" v-if="item.updated">Update {{ changeTime(item.updated) }}</div>
              <div class="is-size-7" v-if="item.initial">Initial</div><div class="is-size-7" v-else>Receipt {{ changeTime(item.receiptTime) }}</div>
              <div class="is-size-7" v-if="showCompare && restItem(item.id)">REST: {{ restItem(item.id).odds }}</div>
              <div class="is-size-7" v-if="item.selection">{{ item.selection.status }}</div>
              <div class="is-size-7" v-if="item.selection">Market {{ item.market.status }} <span v-if="item.market.inPlay">In Play</span></div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
<!--    <section class="section">-->
<!--      <p>Last send/receive delta {{ delta }}ms (Peak {{ worstDelta }}ms)</p>-->
<!--      <p>Change delta {{lastChange}}ms (Peak {{ sinceChange }}ms). Update {{lastUpdate}} (Peak {{ sinceUpdate }}ms).</p>-->
<!--      <div class="table-container">-->
<!--        <table class="table">-->
<!--          <thead>-->
<!--            <tr>-->
<!--              <th>Provider</th>-->
<!--              <th>Event</th>-->
<!--              <th>Time</th>-->
<!--              <th>Market</th>-->
<!--              <th>Selection</th>-->
<!--              <th>Odds</th>-->
<!--              <th>Receipt Time</th>-->
<!--              <th>Update Time</th>-->
<!--              <th>Status</th>-->
<!--            </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--            <tr v-for="(item, index) in odds" :key="index">-->
<!--              <td>{{ item.provider }}</td>-->
<!--              <td>{{ item.event.name }}</td>-->
<!--              <td>{{ eventTime(item.event.time) }}</td>-->
<!--              <td>{{ item.market.name }}</td>-->
<!--              <td>{{ item.selection.name }}</td>-->
<!--              <td><span v-if="item.odds">{{ item.odds }}</span><span v-else-if="item.back || item.lay || item.last">{{ item.back }}|{{ item.last }}|{{ item.lay }}</span></td>-->
<!--              <td><span v-if="item.receiptTime">{{ changeTime(item.receiptTime) }}</span></td>-->
<!--              <td><span v-if="item.updated">{{ changeTime(item.updated) }}</span></td>-->
<!--              <td>{{ item.selection.status }}</td>-->
<!--            </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'OddsSocket',
  data () {
    return {
      sport: false,
      competition: false,
      provider: false,
      hasMarket: false,
      race: false,
      showCompare: false
    };
  },
  computed: {
    ws () {
      return this.$store.state.socket.ws;
    },
    odds () {
      return this.$store.state.socket.odds;
    },
    restOdds () {
      return this.$store.state.odds;
    },
    columns () {
      const columns = [];
      this.odds.forEach(item => {
        if (!columns.find(c => c.provider === item.provider && c.market === item.market.name)) {
          columns.push({
            scraper: 'scraper',
            provider: item.provider,
            market: item.market.name
          });
        }
      });
      return columns.sort((a, b) => {
        if (a.scraper === b.scraper) {
          if (a.provider === b.provider) {
            return a.market.localeCompare(b.market);
          } else {
            return a.provider.localeCompare(b.provider);
          }
        } else {
          if (!a.scraper) {
            return 1;
          }
          if (!b.scraper) {
            return -1;
          }
          return a.scraper.localeCompare(b.scraper);
        }
      });
    },
    scrapers () {
      const scrapers = this.columns.map(a => a.scraper);
      const distinct = [...new Set(scrapers)];
      return distinct.map(scraper => {
        return {
          columns: scrapers.filter(s => s === scraper).length,
          scraper: scraper
        };
      });
    },
    providers () {
      return this.scrapers.flatMap(scraper => this.scraperProviders(scraper));
    },
    raceMarkets () {
      const markets = this.columns.map(a => a.market);
      return [...new Set(markets)];
    },
    markets () {
      return this.scrapers.flatMap(scraper => this.scraperProviders(scraper).flatMap(provider => this.scraperProviderMarkets(scraper, provider)));
    },
    horses () {
      const horses = {};
      this.odds.forEach(item => {
        if (horses[item.selection.id] === undefined) {
          horses[item.selection.id] = [];
        }
        horses[item.selection.id].push(item);
      });
      return Object.values(horses).sort((a, b) => {
        if (!a[0].selection.name && !b[0].selection.name) {
          return 0;
        } else if (!a[0].selection.name) {
          return -1;
        } else if (!b[0].selection.name) {
          return 1;
        }
        return a[0].selection.name.localeCompare(b[0].selection.name);
      }).map(horse => {
        const integerIndexed = {};
        horse.forEach(a => {
          const aIndex = this.columns.findIndex(c => c.provider === a.provider && c.market === a.market.name);
          integerIndexed[aIndex] = a;
        });
        const returnArray = [];
        for (let i = 0; i < this.columns.length; i++) {
          if (integerIndexed[i] === undefined) {
            returnArray.push(null);
          } else {
            returnArray.push(integerIndexed[i]);
          }
        }
        return returnArray;
        // }).sort((a, b) => {
        // const aMax = Math.max(...a.filter(item => item && item.market.name === 'Winner' && (item.back || item.odds)).map(item => {
        //   if (item.odds) {
        //     return item.odds;
        //   } else {
        //     return item.back;
        //   }
        // }));
        // const bMax = Math.max(...b.filter(item => item && item.market.name === 'Winner' && (item.back || item.odds)).map(item => {
        //   if (item.odds) {
        //     return item.odds;
        //   } else {
        //     return item.back;
        //   }
        // }));
        // return aMax - bMax;
      });
    },
    firstName () {
      if (this.first) {
        const firstIndex = this.selected.findIndex(i => this.horses[i].find(m => m).selection.id === this.first);
        return this.horses[this.selected[firstIndex]].find(m => m).selection.name;
      } else {
        return this.horses[this.selected[0]].find(m => m).selection.name;
      }
    },
    delta () {
      return this.$store.state.socket.delta;
    },
    worstDelta () {
      return this.$store.state.socket.worstDelta;
    },
    sinceScrape () {
      return this.$store.state.socket.sinceScrape;
    },
    sinceChange () {
      return this.$store.state.socket.sinceChange;
    },
    sinceUpdate () {
      return this.$store.state.socket.sinceUpdate;
    },
    lastUpdate () {
      return this.$store.state.socket.lastUpdate;
    },
    lastChange () {
      return this.$store.state.socket.lastChange;
    },
    lastScrape () {
      return this.$store.state.socket.lastScrape;
    },
    bookmakers () {
      return this.$store.state.providers;
    },
    oddsMarkets () {
      return this.$store.state.markets;
    },
    events () {
      return this.$store.getters.events;
    },
    competitions () {
      return this.$store.state.competitions;
    },
    sports () {
      return this.$store.state.sports;
    }
  },
  methods: {
    restItem (id) {
      return this.odds.find(a => a.id === id);
    },
    eventTime (time) {
      return moment.unix(time).format('D/MMM/YY HH:mm');
    },
    changeTime (time) {
      return moment(time).format('HH:mm:ss');
    },
    changeRace () {
      this.market = 'all';
    },
    changeSport () {
      let filter = '';
      if (this.provider) {
        filter += 'provider=' + this.provider + '&';
        this.$store.dispatch('getSports', filter);
      }
      if (this.sport) {
        filter += 'sport=' + this.sport + '&';
      }
      this.$store.dispatch('getCompetitions', filter);
      if (this.competition) {
        filter += 'competition=' + this.competition + '&';
      }
      this.$store.dispatch('getMarkets', filter);
      if (this.hasMarket) {
        filter += 'market=' + this.hasMarket + '&';
      }
      this.$store.dispatch('getEvents', filter);
    },
    changeCompetition () {
      let filter = '';
      if (this.sport) {
        filter += 'sport=' + this.sport + '&';
      }
      if (this.competition) {
        filter += 'competition=' + this.competition + '&';
      }
      if (this.provider) {
        filter += 'provider=' + this.provider + '&';
      }
      this.$store.dispatch('getMarkets', filter);
      if (this.hasMarket) {
        filter += 'market=' + this.hasMarket + '&';
      }
      this.$store.dispatch('getEvents', filter);
    },
    changeMarket () {
      let filter = '';
      if (this.sport) {
        filter += 'sport=' + this.sport + '&';
      }
      if (this.competition) {
        filter += 'competition=' + this.competition + '&';
      }
      if (this.provider) {
        filter += 'provider=' + this.provider + '&';
      }
      if (this.hasMarket) {
        filter += 'market=' + this.hasMarket + '&';
      }
      this.$store.dispatch('getEvents', filter);
    },
    scraperProviders (scraper) {
      const providers = this.columns.filter(c => c.scraper === scraper.scraper).map(a => a.provider);
      const distinct = [...new Set(providers)];
      return distinct.map(provider => {
        return {
          columns: providers.filter(s => s === provider).length,
          provider: provider
        };
      });
    },
    scraperProviderMarkets (scraper, provider) {
      const markets = this.columns.filter(c => c.scraper === scraper.scraper && c.provider === provider.provider).map(a => a.market);
      const distinct = [...new Set(markets)];
      return distinct.map(market => {
        return {
          columns: markets.filter(s => s === market).length,
          market: market
        };
      });
    },
    subscribe () {
      console.log('subscribe', this.race);
      this.showCompare = false;
      this.$store.dispatch('subscribe', {
        sport: this.sport,
        event: this.race
      });
    },
    compare () {
      this.showCompare = true;
      this.$store.dispatch('getRaceOdds', this.race);
    }
  },
  mounted () {
    this.$store.dispatch('getSports');
    this.$store.dispatch('getCompetitions');
    this.$store.dispatch('getMarkets');
    this.$store.dispatch('getProviders');
    this.$store.dispatch('getOdds');
    this.$store.dispatch('getEvents');
  }
};
</script>
