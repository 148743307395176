<template>
  <tr>
    <td>{{ dictionary.type }}</td>
    <td>
      <div class="field has-addons">
        <div class="control">
          <input v-model="dictionary.string" class="input">
        </div>
        <div class="control">
          <button @click="edit" class="button is-primary">Edit</button>
        </div>
      </div></td>
    <td>{{ dictionary.sport }}</td>
    <td>{{ time }}</td>
    <td><div v-for="(match, index) in dictionary.matches" :key="index">{{ match.string }} <span v-if="match.provider">:{{ match.provider }}</span> <a class="button is-small is-danger" @click="removeMatch(index)">-</a></div>
      <div class="field has-addons">
        <div class="control">
          <input v-model="matchString" class="input is-small">
        </div>
        <div class="control">
          <button @click="addMatch" class="button is-primary is-small">+</button>
        </div>
      </div>
    </td>
    <td>
      <button v-if="selected" @click="deselect" class="button is-warning">Deselect</button>
      <button v-else @click="select" class="button is-primary">Select</button>
    </td>
  </tr>
</template>

<script>

import moment from 'moment';

export default {
  name: 'DictionaryLine',
  props: ['dictionary'],
  data () {
    return {
      matchString: ''
    };
  },
  computed: {
    selected () {
      return this.$parent.selected.find(a => a === this.dictionary._id);
    },
    time () {
      return moment.unix(this.dictionary.time).format('DD/MM HH:mm');
    }
  },
  methods: {
    select () {
      this.$parent.selected.push(this.dictionary._id);
    },
    deselect () {
      const index = this.$parent.selected.findIndex(a => a === this.dictionary._id);
      this.$parent.selected.splice(index, 1);
    },
    edit () {
      this.$store.dispatch('updateCanonical', {
        id: this.dictionary._id,
        string: this.dictionary.string
      });
    },
    removeMatch (index) {
      this.dictionary.matches.splice(index, 1);
      this.$store.dispatch('updateMatches', {
        id: this.dictionary._id,
        matches: this.dictionary.matches
      });
    },
    addMatch () {
      this.dictionary.matches.push({
        string: this.matchString
      });
      this.$store.dispatch('updateMatches', {
        id: this.dictionary._id,
        matches: this.dictionary.matches
      });
      this.matchString = '';
    }
  }
};
</script>
