<template>
  <tr>
    <td>{{ odds.scrapeProvider }}</td>
    <td>{{ odds.provider }}</td>
    <td>{{ odds.event.name }}</td>
    <td>{{ eventTime }}</td>
    <td>{{ odds.market.name }}</td>
    <td>{{ odds.selection.name }}</td>
    <td v-if="odds.odds">{{ odds.odds }}</td>
    <td v-else>{{ odds.back }}|{{ odds.last }}|{{ odds.lay }}</td>
    <td>{{ changedTime }}</td>
    <td>{{ odds.selection.status }}</td>
  </tr>
</template>

<script>
import moment from 'moment';
export default {
  name: 'OddsLine',
  props: ['odds'],
  data () {
    return {
      eventTime: moment.unix(this.odds.event.time).format('D/MMM/YY HH:mm'),
      changedTime: moment(this.odds.changed).format('D/MMM/YY HH:mm')
    };
  }
};
</script>
