<template>
  <div v-if="requests">
    <div v-if="show">
      <div v-for="(scrape, key) in requests" :key="key + '_requests'" class="message" :class="{'is-info': !status || (scrape.requestTime > status.receipt - 1000 && !status[key]), 'is-warning': status && !status[key] && scrape.requestTime <= status.receipt + 1000 }">
        <div class="message-body">
          {{ scrape.url }}<br>
          Request: {{ $parent.showTime(scrape.requestTime) }}
        </div>
      </div>
    </div>
    <a class="button is-small" @click="show = !show">{{ Object.keys(requests).length }} items</a>
  </div>
</template>

<script>
export default {
  name: 'Requests',
  props: ['status', 'requests', 'fullStatus'],
  data () {
    return {
      show: false
    };
  }
};
</script>
