<template>
  <tr>
    <td>{{ possible.provider }}</td>
    <td>{{ possible.type }}</td>
    <td>{{ name }}</td>
    <td>
      <button class="button is-small is-info" v-html="hidden ? 'Show' : 'Hide'" @click="hidden = !hidden"></button>
      <vue-json-pretty :class="{'is-hidden':hidden}" :data="possible" :showDoubleQuotes="false"></vue-json-pretty></td>
    <td><div class="field has-addons">
      <div class="control is-expanded">
        <div class="select is-fullwidth">
          <select v-model="match">
            <option v-for="(match, index) in possible.matches" :key="index" :value="match">{{ matchName(match) }} {{ match.score }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <button class="button is-primary" @click="mergePossible">Match</button>
      </div>
      <div class="control">
        <button class="button is-info" @click="createCanonical">Create New Canonical</button>
      </div>
    </div></td>
  </tr>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';

export default {
  name: 'PossibleLine',
  props: ['possible'],
  components: {
    VueJsonPretty
  },
  data () {
    return {
      hidden: true,
      match: this.possible.matches[0]
    };
  },
  computed: {
    name () {
      return this.possible.string;
    }
  },
  methods: {
    matchName (match) {
      if (typeof match.field === 'string') {
        return match.field;
      } else {
        return match.field.name;
      }
    },
    createCanonical () {
      this.$store.dispatch('createCanonical', {
        _id: this.possible._id,
        type: this.possible.type,
        sport: this.possible.sport,
        time: this.possible.time,
        string: this.possible.string,
        provider: this.possible.object ? this.possible.object.scrapeProvider : undefined,
        matches: []
      });
    },
    mergePossible () {
      this.$store.dispatch('mergePossible', {
        possible: this.possible._id,
        type: this.possible.type,
        canonical: this.match.field.id || this.match.object._id
      });
    }
  }
};
</script>
