<template>
  <div class="about">
    <h1 class="title">Possible Matches</h1>
    <div class="message">
      <div class="message-body">
        Shows top 20 possible matches<br>
        If the appropriate match is in the dropdown, select it and hit match.<br>
        If not, hit Create New Canonical. Possible options are shortened for memory reasons. So you may find a match in Dictionary after creating the new canonical
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <button class="button is-static">Type</button>
      </div>
      <div class="control">
        <div class="select">
          <select v-model="filter.type" @change="doFilter">
            <option>competition</option>
            <option>event</option>
            <option>selection</option>
            <option>team</option>
          </select>
        </div>
      </div>
      <div class="control">
        <input class="input" placeholder="Search" v-model="filter.search">
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Scraper</th>
          <th>Type</th>
          <th>Name</th>
          <th>Data</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <possible-line v-for="possible in possibles" :key="possible._id" :possible="possible"></possible-line>
      </tbody>
    </table>
  </div>
</template>

<script>
import PossibleLine from '@/components/PossibleLine';
export default {
  name: 'Possibles',
  components: { PossibleLine },
  data () {
    return {
      filter: {
        type: 'competition',
        search: '',
        skip: 0,
        limit: 50
      }
    };
  },
  computed: {
    possibles () {
      return this.$store.state.possibles;
    }
  },
  methods: {
    doFilter () {
      this.$store.dispatch('getPossibles', this.filter);
    }
  },
  watch: {
    'filter.search' () {
      this.$store.dispatch('getPossibles', this.filter);
    }
  },
  mounted () {
    setTimeout(() => {
      this.$store.dispatch('getPossibles', this.filter);
    }, 100);
  }
};
</script>
